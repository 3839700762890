/**
 * @name: 订单管理员接口
 * @author: itmobai
 * @date: 2023-07-20 11:25
 * @description：订单管理员接口
 * @update: 2023-07-20 11:25
 */
import { IPageRes } from "@/apis/page";
import { get, postJ } from "@/request";
import { IOrderManage, IOrderManageParam } from "./types";

/**
 * 订单管理员分页查询
 * @param params
 * @returns
 */
export const orderAdministratorQueryByPageApi = (params: IOrderManageParam) => get<IPageRes<IOrderManage[]>>("/mall/orderAdministrator/queryByPage", params)
/**
 * 订单管理员新增
 * @param data
 * @returns
 */
export const orderAdministratorCreateApi = (data: IOrderManage) => postJ("/mall/orderAdministrator/create", data)
/**
 * 订单管理员修改
 * @param data
 * @returns
 */
export const orderAdministratorModifyApi = (data: IOrderManage) => postJ("/mall/orderAdministrator/modify", data)
/**
 * 查询订单详情
 * @param id
 * @returns
 */
export const orderAdministratorDetailApi = (id: string) => get<IOrderManage>("/mall/orderAdministrator/detail/" + id)
/**
 * 重置密码
 * @param id
 * @returns
 */
export const orderAdministratorResetApi = (id: string) => postJ("/mall/orderAdministrator/reset", {id})
