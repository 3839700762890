/**
 * @name: 站点接口
 * @author: itmobai
 * @date: 2023-07-20 10:32
 * @description：站点接口
 * @update: 2023-07-20 10:32
 */
import { get, postJ } from "@/request";
import { ISite, ISiteParam } from "./types";
import { IPageRes } from "@/apis/page";

/**
 * 站点下拉列表
 * @returns
 */
export const mallSiteQueryByListApi = (status?: number) => get<ISite[]>("/mall/site/queryByList", {status})

/**
 * 站点分页查询
 * @param query 查询参数
 * @returns
 */
export const mallSiteQueryByPageApi = (query: ISiteParam) => get<IPageRes<ISite[]>>("/mall/site/queryByPage", query)

/**
 * 修改
 * @param data
 * @returns
 */
export const mallSiteModifyApi = (data: ISite) => postJ("/mall/site/modify", data)

/**
 * 创建
 * @param data
 * @returns
 */
export const mallSiteCreateApi = (data: ISite) => postJ("/mall/site/create", data)
/**
 * 详情
 * @param id
 * @returns
 */
export const mallSiteDetailApi = (id: string) => get<ISite>("/mall/site/detail/" + id)
