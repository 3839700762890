
/**
 * @name: 订单管理员
 * @author: itmobai
 * @date: 2023-07-20 10:02
 * @description：订单管理员
 * @update: 2023-07-20 10:02
 */
import { Vue, Component } from "vue-property-decorator"
import { orderAdministratorQueryByPageApi, orderAdministratorCreateApi, orderAdministratorModifyApi, orderAdministratorDetailApi, orderAdministratorResetApi } from "@/apis/site/orderManage"
import { IOrderManage, IOrderManageParam } from "@/apis/site/orderManage/types"
import { mallSiteQueryByListApi } from "@/apis/site/list"
import { ISite } from "@/apis/site/list/types"
import { ICrudOption } from "@/types/m-ui-crud"
import { checkPhone } from "@/constants/validators"

@Component({})
export default class OrderManagePage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: IOrderManage[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: IOrderManageParam = {
    page: 1,
    limit: 10
  }
  // 表单数据
  modelForm: Partial<IOrderManage> = {}
  // 配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    dialogWidth: '600px',
    addTitle: '新增订单管理员',
    editTitle: '编辑订单管理员',
    column: [
      {
        label: "ID",
        prop: "id",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true
      },
      {
        label: "姓名",
        prop: "nameKeywords",
        hide: true,
        editHide: true,
        addHide: true,
        search: true,
        placeholder: "输入姓名/手机号模糊搜索"
      },
      {
        label: "姓名",
        prop: "name",
        overHidden: true,
        maxlength: 20,
        span: 24,
        rules: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ]
      },
      {
        label: "手机号(账号)",
        prop: "phone",
        align: "center",
        width: 180,
        maxlength: 11,
        span: 24,
        rules: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {validator: checkPhone, trigger: "blur"}
        ]
      },
      {
        label: "密码",
        prop: "password",
        type: "password",
        hide: true,
        editHide: true,
        maxlength: 20,
        rules: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ]
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
        addHide: true,
        editHide: true
      },
      {
        label: "站点",
        prop: "siteId",
        span: 24,
        search: true,
        type: "select",
        dicData: [],
        hide: true,
        rules: [
          {required: true, message: '请选择站点', trigger: 'blur'},
        ]
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        addHide: true,
        editHide: true,
        slot: true,
      }
    ]
  }

  openAdd() {
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
    this.getSiteSelect()
  }

  openEdit(row: IOrderManage, index: number) {
    orderAdministratorDetailApi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
      this.getSiteSelect()
    })
  }

  getList () {
    this.tableLoading = true
    orderAdministratorQueryByPageApi(this.queryForm).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  rowSave (form: IOrderManage, done: Function, loading: Function) {
    orderAdministratorCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IOrderManage, done: Function, loading: Function) {
    orderAdministratorModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  statusChange (id: string, status: number) {
    orderAdministratorModifyApi({id, status} as IOrderManage).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  resetPwd(row: IOrderManage, index: number) {
    this.$confirm('是否确认重置密码为123456？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      orderAdministratorResetApi(row.id).then(e => {
        if (e) {
          this.$message.success("操作成功!")
          this.getList()
        }
      })
    }).catch(() => {

    })
  }

  getSiteSelect () {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  created () {
    this.getList()
    this.getSiteSelect()
  }
}
